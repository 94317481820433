import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: '/',
      component: () => import('@/views/dashboard/index.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
        path: '/countries',
        name: 'countries',
        component: () =>
            import ('@/views/countries/index.vue'),
        meta: {
            pageTitle: 'Countries',
            breadcrumb: [{
                text: 'Countries',
                active: true,
            }, ],
            
        },
      },
    {
      path: '/states',
      name: 'states',
      component: () =>
          import ('@/views/states/index.vue'),
      meta: {
          pageTitle: 'States',
          breadcrumb: [{
              text: 'States',
              active: true,
          }, ],
          
      },
    },
    {
        path: '/specializations',
        name: 'specializations',
        component: () =>
            import ('@/views/specializations/index.vue'),
        meta: {
            pageTitle: 'Specializations',
            breadcrumb: [{
                text: 'Specializations',
                active: true,
            }, ],
            
        },
    },
    {
        path: '/doctors',
        name: 'doctors',
        component: () =>
            import ('@/views/doctors/index.vue'),
        meta: {
            pageTitle: 'Doctors',
            breadcrumb: [{
                text: 'Docotors',
                active: true,
            }, ],
            
        },
    },
    
    {
        path: '/companies',
        name: 'companies',
        component: () =>
            import ('@/views/companies/index.vue'),
        meta: {
            pageTitle: 'Companies',
            breadcrumb: [{
                text: 'Companies',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/hospitals',
        name: 'hospitals',
        component: () =>
            import ('@/views/hospitals/index.vue'),
        meta: {
            pageTitle: 'Hospitals',
            breadcrumb: [{
                text: 'Hospitals',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/createHospital',
        name: 'createHospital',
        component: () =>
            import ('@/views/hospitals/create.vue'),
        meta: {
            pageTitle: 'Create Hospital',
            breadcrumb: [{
                text: 'Create Hospital',
                active: true,
            }, ],
            
        },
      },

      {
        path: '/pharmacies',
        name: 'pharmacies',
        component: () =>
            import ('@/views/pharmacies/index.vue'),
        meta: {
            pageTitle: 'Pharmacies',
            breadcrumb: [{
                text: 'Pharmacies',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/createPharmacy',
        name: 'createPharmacy',
        component: () =>
            import ('@/views/pharmacies/create.vue'),
        meta: {
            pageTitle: 'Create Pharmacy',
            breadcrumb: [{
                text: 'Create Pharmacy',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/pharmaceuticals',
        name: 'pharmaceuticals',
        component: () =>
            import ('@/views/pharmaceuticals/index.vue'),
        meta: {
            pageTitle: 'Pharmaceutical',
            breadcrumb: [{
                text: 'Pharmaceuticals',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/pharmaciesReport',
        name: 'pharmaciesReport',
        component: () =>
            import ('@/views/pharmaciesReport/index.vue'),
        meta: {
            pageTitle: 'Pharmacies Report',
            breadcrumb: [{
                text: 'Pharmacies Report',
                active: true,
            }, ],
            
        },
      },
      
      
      {
        path: '/visits',
        name: 'visits',
        component: () =>
            import ('@/views/visits/index.vue'),
        meta: {
            pageTitle: 'Visits',
            breadcrumb: [{
                text: 'Visits',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/createVisit',
        name: 'createVisit',
        component: () =>
            import ('@/views/visits/create.vue'),
        meta: {
            pageTitle: 'Create Visit',
            breadcrumb: [{
                text: 'Create Visit',
                active: true,
            }, ],
            
        },
      },

      {
        path: '/regions',
        name: 'regions',
        component: () =>
            import ('@/views/regions/index.vue'),
        meta: {
            pageTitle: 'Regions',
            breadcrumb: [{
                text: 'Regions',
                active: true,
            }, ],
            
        },
      },
      {
        path: '/medicalReps',
        name: 'medicalReps',
        component: () =>
            import ('@/views/medicalReps/index.vue'),
        meta: {
            pageTitle: 'MedicalReps',
            breadcrumb: [{
                text: 'MedicalReps',
                active: true,
            }, ],
            
        },
      },

  
  

//   {
//       path: '/companies',
//       name: 'companies',
//       component: () =>
//           import ('@/views/company/index.vue'),
//       meta: {
//           pageTitle: 'Companies',
//           breadcrumb: [{
//               text: 'Companies',
//               active: true,
//           }, ],
//       },
//   },

  {
      path: '/categories',
      name: 'categories',
      component: () =>
          import ('@/views/category/index.vue'),
      meta: {
          pageTitle: 'Categories',
          breadcrumb: [{
              text: 'categories',
              active: true,
          }, ],
      },
  },

    //   products
    {
        path: '/products',
        name: 'products',
        component: () =>
            import ('@/views/products/index.vue'),
        meta: {
            pageTitle: 'Products',
            breadcrumb: [{
                text: 'products',
                active: true,
            }, ],
        },
    },
    {
        path: '/create-machine',
        name: 'create-machine',
        component: () =>
            import ('@/views/products/machine.vue'),
        meta: {
            pageTitle: 'Products',
            breadcrumb: [{
                text: 'create machine',
                active: true,
            }, ],
        },
    },
    {
        path: '/product-machine/:id',
        name: 'product-machine',
        component: () =>
            import ('@/views/products/machine.vue'),
        meta: {
            pageTitle: 'product-machine',
            breadcrumb: [{
                text: 'products',
                active: true,
            }, ],
        },
    },

    {
      path: '/admins',
      name: 'admins',
      component: () =>
          import ('@/views/admins/index.vue'),
      meta: {
          pageTitle: 'Admins',
          breadcrumb: [{
              text: 'Admins',
              active: true,
          }, ],
      },
    },
    {
        path: '/permissions',
        name: 'permissions',
        component: () =>
            import ('@/views/admins/permissions.vue'),
        meta: {
            pageTitle: 'Permissions',
            breadcrumb: [{
                text: 'Permissions',
                active: true,
            }, ],
        },
    },

  {
      path: '/login',
      name: 'login',
      component: () =>
          import ('@/views/Auth/Login.vue'),
      meta: {
          layout: 'full',
      },
  },
  {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () =>
          import ('@/views/Auth/ForgotPassword-v2.vue'),
      meta: {
          layout: 'full',
      },
  },
  {
      path: '/account-setting',
      name: 'account-setting',
      component: () =>
          import ('@/views/account-setting/AccountSetting.vue'),
      meta: {
          pageTitle: 'Account Setting',
      },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () =>
        import ('@/views/customer/index.vue'),
    meta: {
        pageTitle: 'Customer',
        breadcrumb: [{
            text: 'Customer',
            active: true,
        }, ],
    },
    },
    {
        path: '/customers-profile/:id',
        name: 'customers-profile',
        component: () =>
            import ('@/views/customer/profile.vue'),
        meta: {
            pageTitle: 'Profile',
            breadcrumb: [{
                text: 'Profile',
                active: true,
            }, ],
        },
    },
    {
        path: '/workShop',
        name: 'workShop',
        component: () =>
            import ('@/views/workShop/index.vue'),
        meta: {
            pageTitle: 'WorkShop',
            breadcrumb: [{
                text: 'WorkShop',
                active: true,
            }, ],
        },
    },
    {
        path: '/captains',
        name: 'captains',
        component: () =>
            import ('@/views/captain/index.vue'),
        meta: {
            pageTitle: 'Captains',
            breadcrumb: [{
                text: 'captains',
                active: true,
            }, ],
        },
    },

    {
        path: '/regions',
        name: 'regions',
        component: () =>
            import ('@/views/region/index.vue'),
        meta: {
            pageTitle: 'Regions',
            breadcrumb: [{
                text: 'Regions',
                active: true,
            }, ],
        },
    },

    {
        path: '/component',
        name: 'component',
        component: () =>
            import ('@/views/component/index.vue'),
        meta: {
            pageTitle: 'Component',
            breadcrumb: [{
                text: 'Component',
                active: true,
            }, ],
        },
    },

    

    
    {
        path: '/workShop-profile/:id',
        name: 'workShop-profile',
        component: () =>
            import ('@/views/workShop/profile.vue'),
        meta: {
            pageTitle: 'Workshop',
            breadcrumb: [{
                text: 'WorkShop',
                active: true,
            }, ],
        },
    },


    // unit Route
    {
        path: '/unit',
        name: 'unit',
        component: () =>
            import ('@/views/unit/index.vue'),
        meta: {
            pageTitle: 'Unit',
            breadcrumb: [{
                text: 'Unit',
                active: true,
            }, ],
        },
    },
    // Coupon Route
    {
        path: '/coupon',
        name: 'coupon',
        component: () =>
            import ('@/views/coupon/index.vue'),
        meta: {
            pageTitle: 'Coupon',
            breadcrumb: [{
                text: 'coupon',
                active: true,
            }, ],
        },
    },

    
    // machineType Route
    {
        path: '/machineType',
        name: 'machineType',
        component: () =>
            import ('@/views/machineType/index.vue'),
        meta: {
            pageTitle: 'Machine Type',
            breadcrumb: [{
                text: 'Machine Type',
                active: true,
            }, ],
        },
    },
    {
        path: '/machine',
        name: 'machine',
        component: () =>
            import ('@/views/machine/index.vue'),
        meta: {
            pageTitle: 'Machine',
            breadcrumb: [{
                text: 'Machine',
                active: true,
            }, ],
        },
    },
    {
        path: '/machineSize',
        name: 'machineSize',
        component: () =>
            import ('@/views/machineSize/index.vue'),
        meta: {
            pageTitle: 'machine Size',
            breadcrumb: [{
                text: 'machine Size',
                active: true,
            }, ],
        },
    },
    {
        path: '/chooseFactor',
        name: 'chooseFactor',
        component: () =>
            import ('@/views/chooseFactor/index.vue'),
        meta: {
            pageTitle: 'Choose Factor',
            breadcrumb: [{
                text: 'Choose Factor',
                active: true,
            }, ],
        },
    },
    

    
    
    // orders Route
    {
        path: '/orders',
        name: 'orders',
        component: () =>
            import ('@/views/orders/index.vue'),
        meta: {
            pageTitle: 'Orders',
            breadcrumb: [{
                text: 'Orders',
                active: true,
            }, ],
        },
    },
    {
        path: '/order-details/:id',
        name: 'order-details',
        component: () =>
            import ('@/views/orders/details.vue'),
        meta: {
            pageTitle: 'Orders',
            breadcrumb: [{
                text: 'Orders',
                active: true,
            }, ],
        },
    },

    // Contacts Route
    {
        path: '/contacts',
        name: 'contacts',
        component: () =>
            import ('@/views/contact/index.vue'),
        meta: {
            pageTitle: 'Contacts',
            breadcrumb: [{
                text: 'Contacts',
                active: true,
            }, ],
        },
    },
   
   

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
        path: '/notAuthorized',
        name: 'notAuthorized',
        component: () => import('@/views/error/NotAuthorized.vue'),
        meta: {
            layout: 'full',
        }
    }
  ],
})


router.beforeEach((to, from, next) => {
    if (to.meta.pageTitle) {
        document.title = ` ${process.env.VUE_APP_NAME} || ${to.meta.pageTitle}`;
    }

    var $permission = $cookies.get(process.env.VUE_APP_NAME + '_Privilege')
    var $exceptRoute = ['login', 'notAuthorized', 'error-404', 'customers-profile', '/']
    if ($permission && !$permission.includes(to.name) && !$exceptRoute.includes(to.name)) {
        next({ name: 'notAuthorized'})
    }

  const token = $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN')
      // If logged in, or going to the Login page.
  if (token || to.name === 'login' || to.name === 'forgot-password') {
      // Continue to page.
      next()
  } else {
      // Not logged in, redirect to login.
      next({ name: 'login' })
  }
});


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
