import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import axios from "./libs/axios"
Vue.use(axios)
window.axios = require('axios').default;
// const axios = require('axios').default;

// import Vue cookies 
import VueCookies from 'vue-cookies'


// import map
// import  VueGoogleMaps from '@fawmi/vue-google-maps'
// // Vue.use(VueGoogleMaps, {
// //   load: {
// //     key: "AIzaSyAC9-5MhvuAHPF4nckVyOOhbS061RRGhRE"
// //   }
// // })

// Vue.use(VueGoogleMaps, {
//   load: {
//       key: 'AIzaSyAC9-5MhvuAHPF4nckVyOOhbS061RRGhRE',
//       libraries: 'places', // This is required if you use the Autocomplete plugin
//   },
//   installComponents: true
// })

Vue.component('gmap-autocomplete', VueGoogleMaps.Autocomplete);


import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'

Vue.use(VueGoogleMap, {
    load: {
        apiKey: 'AIzaSyAC9-5MhvuAHPF4nckVyOOhbS061RRGhRE',
        libraries: ['places']
    }
})


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAC9-5MhvuAHPF4nckVyOOhbS061RRGhRE',
    libraries: 'places', 
  },
  installComponents: true
})

Vue.use(require('vue-moment'));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
