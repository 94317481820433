import axios from '@/libs/axios'
export default {
  namespaced: true,
  state: {
    optionValue: [{value: ''}],
    productOption: [{  machine_id: '', year: ''}],
    // productOption: [{ product_option_id: '', product_options_value_id: '', unit_id: '', medical_rep_id: '', price: ''}],
    countries: [],
    regions: [],
    machines: [{  machine_size_id: '', oil_capacity: ''}],
    visits: [ { hospitals: [],  hospital_id: '', doctors:[], doctor_id: '',visit_type_id: 1} ],
    permission: [],
    states: [],
    medicalReps: [],
    hospitalTypes: [],
    hospitals: [],
    doctorTypes: [],
    specializations: [],

  },
  getters: {
    GetOptionValue: state => {
        return state.optionValue
    },
    GetProductOption: state => {
      return state.productOption
    },
    GetCountries: state => {
      return state.countries
    },
    GetRegions: state => {
      return state.regions
    },
    GetStates: state => {
      return state.states
    },
    GetMedicalReps: state => {
      return state.medicalReps
    },
    GetMachines: state => {
      return state.machines
    },
    GetHospitalType: state => {
      return state.hospitalTypes
    },
    GetVisits: state => {
      return state.visits
    },
    GetPermission: state => {
      return state.permission
    },
    GetHospitals: state => {
      return state.hospitals
    },
    GetDoctorTypes: state => {
      return state.doctorTypes
    },
    GetSpecializations: state => {
      return state.specializations
    }
  },
  mutations: {
    // option value
    UpdateOptionValue(state, optionValue){
        state.optionValue = optionValue
    },
    AddOptionValue(state, optionValue){
        state.optionValue.push(optionValue)
        // state.optionValue = optionValue
    },
    RemoveOptionValue(state, optionValue){
        state.optionValue.splice(optionValue, 1)
    },

    // productOption
    UpdateProductOption(state, productOption){
      state.productOption = productOption
    },
    AddProductOption(state, productOption){
        state.productOption.push(productOption)
        // state.optionValue = optionValue
    },
    RemoveProductOption(state, productOption){
        state.productOption.splice(productOption, 1)
    },
    // permission
    UpdatePermission(state, permission){
      state.permission = permission
    },

    // Machines
    UpdateMachines(state, machines){
      state.machines = machines
    },
    AddMachines(state, machines){
      state.machines.push(machines)
        // state.optionValue = optionValue
    },
    RemoveMachines(state, machines){
      state.machines.splice(machines, 1)
    },

    // Visits
    UpdateVisit(state, visits){
      state.visits = visits
    },
    AddVisit(state, visits){
      state.visits.push(visits)
    },
    RemoveVisit(state, visits){
      state.visits.splice(visits, 1)
    },



    AddCountries(state, countries){
      state.countries = countries
    },
    AddRegion(state, regions){
      state.regions = regions
    },
    AddState(state, states){
      state.states = states
    },
    AddMedicalRep(state, medicalReps){
      state.medicalReps = medicalReps
    },
    AddHospitalType(state, hospitalType){
      state.hospitalTypes = hospitalType
    },
    AddHospitals(state, hospitals) {
      state.hospitals = hospitals
    },
    AddDoctorTypes(state, doctorTypes) {
      state.doctorTypes = doctorTypes
    }, 
    AddSpecializations (state, specializations) {
      state.specializations = specializations
    }
  },
  actions: {
    GetCountries({commit}){
      axios.get(`/lkbCountries`,{headers: {"Authorization": $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN'),}})
      .then(response => {
        commit('AddCountries',response.data.data)
      })
    },

    GetRegions({commit}){
      axios.get(`/lkbRegions`,{headers: {"Authorization": $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN'),}})
      .then(response => {
        commit('AddRegion',response.data.data)
      })
    },

    GetStates({commit}){
      axios.get(`/lkbStates`,{headers: {"Authorization": $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN'),}})
      .then(response => {
        commit('AddState',response.data.data)
      })
    },
    
    GetMedicalReps({commit}){
      axios.get(`/lkbMedicalRep`,{headers: {"Authorization": $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN'),}})
      .then(response => {
        commit('AddMedicalRep',response.data.data)
      })
    },

    GetHospitalType({commit}){
      axios.get(`/lkbHospitalType`,{headers: {"Authorization": $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN'),}})
      .then(response => {
        commit('AddHospitalType',response.data.data)
      })
    },

    GetHospitalsLkb({commit}){
      axios.get(`/lkbHospitals`,{headers: {"Authorization": $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN'),}})
      .then(response => {
        commit('AddHospitals',response.data.data.hospitals)
        commit('AddDoctorTypes',response.data.data.doctorTypes)
        commit('AddSpecializations',response.data.data.specializations)
        
      })
    },
    
    
  },
}
